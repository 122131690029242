import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Intro from "components/blocks/Intro";
import Section from "components/website/Section";
import { useTranslation } from "react-i18next";
import { PiQuotesFill } from "react-icons/pi";

const reviews = Array.from({ length: 3 }).map((_, i) => ({
  text: `home-review-section-review-${i + 1}-text`,
  name: `home-review-section-review-${i + 1}-name`,
  role: `home-review-section-review-${i + 1}-role`,
}));

const ReviewsSection = () => {
  const { t } = useTranslation();

  return (
    <Section id="reviews" display="flex" gap={12} flexDirection="column">
      <Intro
        name={t("home-review-section-name")}
        title={t("home-review-section-title")}
        description={t("home-review-section-description")}
      />

      <Flex gap={6} flexDirection={["column", "column", "row"]}>
        {reviews.map((review) => (
          <Flex
            key={review.text}
            flexDirection="column"
            alignItems="flex-start"
            flex={1}
            gap={6}
            p={6}
            borderRadius="lg"
            borderWidth={1}
            borderColor="gray.100"
          >
            <Box color="primary.500">
              <PiQuotesFill size="1.5rem" />
            </Box>

            <Text flex={1}>{t(review.text)}</Text>

            <Flex flexDirection="column" gap={0}>
              <Heading as="h3" size="md">
                {t(review.name)}
              </Heading>
              <Text fontSize="xs">{t(review.role)}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Section>
  );
};

export default ReviewsSection;
