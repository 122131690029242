import { Box, Divider, Flex, Heading, Image, Text } from "@chakra-ui/react";
import Intro from "components/blocks/Intro";
import Section from "components/website/Section";
import DashboardImage from "images/features/1_dashboard.png";
import PreferencesImage from "images/features/2_preferences.png";
import CommunicationImage from "images/features/3_communication.png";
import MobileImage from "images/features/4_mobile.png";
import { useTranslation } from "react-i18next";

const images = [
  DashboardImage,
  PreferencesImage,
  CommunicationImage,
  MobileImage,
];

const features = Array.from({ length: 4 }).map((_, i) => ({
  title: `home-features-section-feature-${i + 1}-title`,
  description: `home-features-section-feature-${i + 1}-description`,
  image: images[i],
  keyValues: [
    {
      title: `home-features-section-feature-${i + 1}-key-values-1-title`,
      description: `home-features-section-feature-${i + 1}-key-values-1-description`,
    },
    {
      title: `home-features-section-feature-${i + 1}-key-values-2-title`,
      description: `home-features-section-feature-${i + 1}-key-values-2-description`,
    },
  ],
}));

const FeaturesSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      id="highlight-title"
      display="flex"
      gap={12}
      flexDirection="column"
    >
      <Intro
        name={t("home-features-section-name")}
        title={t("home-features-section-title")}
        description={t("home-features-section-descriptions")}
      />

      {features.map((feature, index) => (
        <Flex
          key={feature.title}
          flexDirection={[
            "column",
            "column",
            index % 2 === 0 ? "row-reverse" : "row",
          ]}
          justifyContent="space-between"
          alignItems="center"
          gap={12}
          py={[4, 8, 12]}
        >
          <Flex
            flexDirection="column"
            gap={4}
            maxW={["full", "full", "lg"]}
            textAlign={["center", "center", "left"]}
          >
            <Heading as="h3" size="xl">
              {t(feature.title)}
            </Heading>

            <Text fontSize="lg">{t(feature.description)}</Text>

            <Divider />

            <Flex gap={3} flexDirection={["column", "column", "row"]}>
              {feature.keyValues.map((keyValue) => (
                <Flex
                  key={keyValue.title}
                  w="full"
                  gap={2}
                  flexDirection="column"
                >
                  <Heading as="h4" size="md">
                    {t(keyValue.title)}
                  </Heading>
                  <Text>{t(keyValue.description)}</Text>
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Box
            w="full"
            maxW={["full", "md", "lg"]}
            p={2}
            backgroundColor="primary.200"
            borderRadius="2xl"
          >
            <Image
              src={feature.image}
              alt="Send emails image"
              loading="lazy"
              boxSize="full"
              objectFit="cover"
              borderRadius="lg"
              boxShadow="sm"
            />
          </Box>
        </Flex>
      ))}
    </Section>
  );
};

export default FeaturesSection;
