import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import Intro from "components/blocks/Intro";
import Section from "components/website/Section";
import { useTranslation } from "react-i18next";

const faqs = Array.from({ length: 6 }).map((_, i) => ({
  question: `home-faq-section-faq-${i + 1}-question`,
  answer: `home-faq-section-faq-${i + 1}-answer`,
}));

const FaqSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      id="faqs"
      display="flex"
      gap={12}
      flexDirection="column"
      alignItems="center"
    >
      <Intro
        name={t("home-faq-section-name")}
        title={t("home-faq-section-title")}
        description={t("home-faq-section-description")}
      />

      <Accordion w="full" maxW="xl" allowToggle>
        {faqs.map((faq) => (
          <AccordionItem key={faq.question} border="none">
            <AccordionButton
              as="div"
              py={6}
              justifyContent="space-between"
              fontFamily="'Expose', sans-serif"
              fontSize="lg"
              fontWeight="medium"
              color="gray.800"
              textAlign="left"
            >
              {t(faq.question)}
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              color="gray.400"
              fontFamily="'Red Hat Display', sans-serif"
            >
              {t(faq.answer)}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <Divider maxW="xl" />

      <Flex maxW="xl" flexDirection="column" gap={2}>
        <Heading as="h3" size="md" textAlign="left">
          {t("home-faq-section-more-questions-title")}
        </Heading>
        <Text textAlign="left">
          {t("home-faq-section-more-questions-description")}
        </Text>
      </Flex>
    </Section>
  );
};

export default FaqSection;
