import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors: {
    primary: {
      "50": "#FFEAF4",
      "100": "#FFBFDE",
      "200": "#FE7FBC",
      "300": "#FE3F9B",
      "400": "#FB017A",
      "500": "#D00165",
      "600": "#B20157",
      "700": "#860141",
      "800": "#59002B",
      "900": "#2D0016",
    },
    gray: {
      25: "#F8FAFC",
      50: "#F1F5F9",
      100: "#E2E8F0",
      200: "#CBD5E1",
      300: "#94A3B8",
      400: "#64748B",
      500: "#475569",
      600: "#334155",
      700: "#1E293B",
      800: "#0F172A",
      900: "#020617",
    },
    red: {
      "50": "#FFEBEA",
      "100": "#FFD8D6",
      "200": "#FFB1AC",
      "300": "#FF8983",
      "400": "#FF6259",
      "500": "#FF3B30",
      "600": "#CC2F26",
      "700": "#99231D",
      "800": "#661813",
      "900": "#330C0A",
    },
    green: {
      "50": "#EAF6EC",
      "100": "#D4EDDA",
      "200": "#A9DCB5",
      "300": "#7ECA8F",
      "400": "#53B96A",
      "500": "#28A745",
      "600": "#208637",
      "700": "#186429",
      "800": "#10431C",
      "900": "#08210E",
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "medium",
        fontFamily: "'Red Hat Display', sans-serif",
        lineHeight: 1.4,
      },
    },
    Heading: {
      baseStyle: {
        color: "gray.800",
        fontWeight: "bold",
        fontFamily: "'Expose', sans-serif",
      },
    },
    Text: {
      baseStyle: {
        color: "gray.400",
        fontFamily: "'Red Hat Display', sans-serif",
      },
    },
  },
});

export default theme;
