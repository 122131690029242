import { Box, Flex, Image } from "@chakra-ui/react";
import logoHeader from "images/logo/header.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Box
      backgroundColor="white"
      borderBottomWidth={1}
      borderBottomColor="gray.100"
      px={6}
      position="sticky"
      top={0}
      zIndex={9}
    >
      <Flex py={[4, 4, 6]}>
        <Link to="/">
          <Image h={[6, 6, 8]} alt="invytly" src={logoHeader} />
        </Link>
      </Flex>
    </Box>
  );
};

export default Navbar;
