import { Box, Button, Flex, Heading, Link, Text } from "@chakra-ui/react";
import Section from "components/website/Section";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Box px={6} py={12}>
      <Section
        id="hero"
        textAlign="center"
        borderRadius="2xl"
        backgroundColor="primary.50"
      >
        <Flex
          flexDirection="column"
          gap={8}
          alignItems="center"
          px={[0, 10, 12]}
          py={6}
        >
          <Heading
            as="h1"
            maxW="4xl"
            mx="auto"
            fontSize={["5xl", "7xl", "8xl"]}
            lineHeight={1}
            fontWeight="extrabold"
            letterSpacing="tight"
            color="primary.500"
            whiteSpace="pre-line"
          >
            {t("home-hero-section-title")}
          </Heading>
          <Text maxW="md" fontSize="xl" color="primary.600">
            {t("home-hero-section-description")}
          </Text>
          <Button
            as={Link}
            href="mailto:info@invytly.com"
            colorScheme="primary"
            size="lg"
            borderRadius="lg"
          >
            {t("home-hero-section-cta")}
          </Button>
        </Flex>
      </Section>
    </Box>
  );
};

export default HeroSection;
