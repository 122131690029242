import { Flex, Heading, Text } from "@chakra-ui/react";

type IntroProps = {
  name: string;
  title: string;
  description: string;
};

const Intro = ({ name, title, description }: IntroProps) => {
  return (
    <Flex gap={4} flexDirection="column" alignItems="center">
      <Text fontSize="sm" fontWeight="medium" color="primary.500">
        {name}
      </Text>
      <Heading fontSize={["4xl", "5xl", "5xl"]} textAlign="center">
        {title}
      </Heading>
      <Text maxW="xl" textAlign="center" fontSize="lg">
        {description}
      </Text>
    </Flex>
  );
};

export default Intro;
